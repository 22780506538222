import AuthManager from '../managers/authManager';
import Config from '../config';

export default class BaseService {
  jsonRequest(url, method, data, baseUrl) {
    let self = this;

    self.baseApiUrl = baseUrl ? baseUrl : Config.api.baseUrl;
    return new Promise(function(resolve, reject) {
      let headers = {
        'Content-Type': 'application/json',
      };

      const auth = AuthManager.getInstance();
      const accessToken = auth.bearerToken;
      if (accessToken) headers['Authorization'] = 'Bearer ' + accessToken;

      fetch(self.baseApiUrl + url, {
        method: method.toUpperCase(),
        headers: headers,
        body: data ? JSON.stringify(data) : null,
      }).then(
        function(results) {
          if (results.status === 401) {
            auth.logOut();
          } else {
            results.json().then(
              function(jsonResult) {
                if (results.ok && results.ok === true) {
                  resolve(jsonResult);
                } else {
                  reject(jsonResult);
                }
              },
              function(jsonError) {
                reject(jsonError);
              }
            );
          }
        },
        function(error) {
          reject(error);
        }
      );
    });
  }
}
