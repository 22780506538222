const Config = () => {
  let environment = require('./env/development').environment;

  if (process.env.REACT_APP_ENVIRONMENT === 'stage') {
    environment = require('./env/stage').environment;
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    environment = require('./env/production').environment;
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'local') {
    environment = require('./env/local').environment;
  }

  return {
    api: {
      baseUrl: environment.api.baseUrl.replace('{host}', window.location.hostname),
    },
    loginRoute: environment.loginRoute,
  };
};

export default Config();
