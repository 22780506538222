import React, { Component } from 'react';
import CurrencyFormat from 'react-currency-format';
import { withStyles } from '@material-ui/core/styles';
import { Search as SearchIcon } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import DateFnsUtils from '@date-io/date-fns';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import {
    Container,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    InputBase,
    IconButton,
    Grid,
    MenuItem,
    FormControl,
    InputLabel,
    TableFooter
} from '@material-ui/core';
import { Select as MaterialSelect } from '@material-ui/core';
import Select from 'react-select';
import AdminService from '../../../services/adminService';
import ErrorManager from '../../../managers/errorManager';


const styles = theme => ({
    searchContainer: {
        marginTop: theme.spacing(3),
        padding: 15
    },
    tableContainer: {
        marginTop: theme.spacing(3),
        overflowX: 'scroll'
    },
    button: {
        marginLeft: 10
    },
    deleteButton: {
        marginLeft: 10,
        backgroundColor: '#E7252A'
    },
    table: {
        minWidth: 750,
    },
    addFab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: '35px',
        right: '35px',
        backgroundColor: '#16203f',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1
    },
    flexRow_Last: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        marginTop: 15
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 10px',
        flexGrow: 1,
        width: 200
    },
    orderDialog: {
        width: 675
    },
    searchBox: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        marginTop: 15
    },
    actionsContainer: {
        padding: 15,
        display: 'flex',
        alignItems: 'center',
        marginTop: 15
    },
    addButton: {
        backgroundColor: '#16203f',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
});


class ConsultantsManager extends Component {

    adminService;

    constructor(props) {
        super(props);

        this.adminService = new AdminService();

        this.state = {
            consultants: [],
            orders: [],
            ordersMeta: {},
            selectedConsultant: undefined,
            orderDialogOpen: false,
            orderDialogEdit: false,
            confirmDeleteOpen: false,
            orderId: '',
            orderPoDate: new Date(),
            orderPoNumber: '',
            orderCompany: '',
            orderProduct: '',
            orderUnitPrice: 0,
            orderQuantity: 0,
            orderTotalSale: 0,
            orderCommissionPct: 0,
            orderTotalCommission: 0,
            orderPaymentStatus: 'unpaid',
            orderCommissionStatus: 'unpaid',
            page: 1,
            pageSize: 9999,
            search: ''
        }
    }

    componentDidMount() {
        this.getConsultants();
    }

    handleFormChange = (event) => {

        if (event.target.name === "orderQuantity") {
            let orderTotalSale = Number(this.state.orderUnitPrice) * Number(event.target.value);
            let commission = Number(orderTotalSale) * (Number(this.state.orderCommissionPct) / 100);
            this.setState({
                orderTotalCommission: Math.round(commission * 100) / 100,
                orderTotalSale: Math.round(orderTotalSale * 100) / 100
            });
        }

        if (event.target.name === "orderUnitPrice") {
            let orderTotalSale = Number(event.target.value) * Number(this.state.orderQuantity);
            let commission = Number(orderTotalSale) * (Number(this.state.orderCommissionPct) / 100);
            this.setState({
                orderTotalCommission: Math.round(commission * 100) / 100,
                orderTotalSale: Math.round(orderTotalSale * 100) / 100
            });
        }

        if (event.target.name === "orderCommissionPct") {
            let commission = Number(this.state.orderTotalSale) * (Number(event.target.value) / 100);
            this.setState({ orderTotalCommission: Math.round(commission * 100) / 100 });
        }

        if (event.target.name === "orderTotalSale") {
            let commission = Number(this.state.orderCommissionPct) * (Number(event.target.value) / 100);
            this.setState({ orderTotalCommission: Math.round(commission * 100) / 100 });
        }

        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleDateChange = date => {
        this.setState({
            orderPoDate: date
        })
    };

    getConsultants = async () => {
        try {
            let response = await this.adminService.getConsultants();
            this.setState({
                consultants: response.consultants
            });
        }
        catch (error) {
            ErrorManager.getInstance().broadcastError(error.message)
        }
    }

    getOrdersForConsultant = async (consultantId) => {
        try {
            let response = await this.adminService.getOrdersForConsultant(consultantId, this.state.page, this.state.pageSize, this.state.search);
            this.setState({
                orders: response.orders,
                ordersMeta: response.meta
            });
        }
        catch (error) {
            ErrorManager.getInstance().broadcastError(error.message)
        }
    }

    addOrderClicked = () => {
        this.setState({
            orderDialogOpen: true,
            orderDialogEdit: false
        });
    }

    updateOrderClicked = (order) => {
        this.setState({
            orderDialogOpen: true,
            orderDialogEdit: true,
            orderId: order.id,
            orderPoDate: order.poDate,
            orderPoNumber: order.poNumber,
            orderCompany: order.company,
            orderProduct: order.product,
            orderUnitPrice: order.unitPrice,
            orderQuantity: order.qty,
            orderTotalSale: order.totalSale,
            orderCommissionPct: order.commissionPercentage,
            orderTotalCommission: order.commission,
            orderPaymentStatus: order.paymentStatus,
            orderCommissionStatus: order.commissionStatus
        });
    }

    closeOrderDialog = () => {
        this.setState({
            orderDialogOpen: false,
            orderDialogEdit: false,
            orderId: '',
            orderPoDate: new Date(),
            orderPoNumber: '',
            orderCompany: '',
            orderProduct: '',
            orderUnitPrice: 0,
            orderQuantity: 0,
            orderTotalSale: 0,
            orderCommissionPct: 0,
            orderTotalCommission: 0,
            orderPaymentStatus: 'unpaid',
            orderCommissionStatus: 'unpaid',
        })
    }

    addOrder = async () => {
        let newOrder = {
            poDate: this.state.orderPoDate,
            poNumber: this.state.orderPoNumber,
            company: this.state.orderCompany,
            product: this.state.orderProduct,
            unitPrice: this.state.orderUnitPrice,
            qty: this.state.orderQuantity,
            totalSale: this.state.orderTotalSale,
            commissionPercentage: this.state.orderCommissionPct,
            commission: this.state.orderTotalCommission,
            paymentStatus: this.state.orderPaymentStatus,
            commissionStatus: this.state.orderCommissionStatus
        };
        await this.adminService.addOrderForConsultant(this.state.selectedConsultant.value.id, newOrder);
        this.closeOrderDialog();
        await this.getOrdersForConsultant(this.state.selectedConsultant.value.id);
    }

    editOrder = async () => {
        let orderToUpdate = {
            poDate: this.state.orderPoDate,
            poNumber: this.state.orderPoNumber,
            company: this.state.orderCompany,
            product: this.state.orderProduct,
            unitPrice: this.state.orderUnitPrice,
            qty: this.state.orderQuantity,
            totalSale: this.state.orderTotalSale,
            commissionPercentage: this.state.orderCommissionPct,
            commission: this.state.orderTotalCommission,
            paymentStatus: this.state.orderPaymentStatus,
            commissionStatus: this.state.orderCommissionStatus
        };
        await this.adminService.updateOrderForConsultant(this.state.selectedConsultant.value.id, this.state.orderId, orderToUpdate);
        this.closeOrderDialog();
        await this.getOrdersForConsultant(this.state.selectedConsultant.value.id);
    }

    handleSelectConsultant = (selectionChanged) => {
        this.setState({
            selectedConsultant: selectionChanged
        });

        this.getOrdersForConsultant(selectionChanged.value.id);
    }

    closeConfirmDialog = () => {
        this.setState({
            confirmDeleteOpen: false,
            orderId: ''
        })
    }

    openConfirmDialog = (orderId) => {
        this.setState({
            confirmDeleteOpen: true,
            orderId
        })
    }

    deleteOrder = async () => {
        await this.adminService.deleteOrderForConsultant(this.state.selectedConsultant.value.id, this.state.orderId);
        this.closeConfirmDialog();
        await this.getOrdersForConsultant(this.state.selectedConsultant.value.id);
    }

    handleSearch = async () => {
        await this.getOrdersForConsultant(this.state.selectedConsultant.value.id);
    }

    render() {
        console.log(Object.keys(this.props))
        const { classes, t } = this.props;
        const {
            consultants,
            selectedConsultant,
            orders,
            ordersMeta,
            orderDialogOpen,
            orderDialogEdit,
            orderPoDate,
            orderPoNumber,
            orderCompany,
            orderProduct,
            orderUnitPrice,
            orderQuantity,
            orderTotalSale,
            orderCommissionPct,
            orderTotalCommission,
            orderPaymentStatus,
            orderCommissionStatus,
            confirmDeleteOpen,
            search
        } = this.state;

        let consultantOptions = consultants.map(c => {
            return {
                label: c.fullName,
                value: c
            }
        })

        return (
            <div>
                <Container maxWidth={"xl"}>
                    <Paper className={classes.searchContainer}>
                        <Select
                            classes={classes}
                            inputId="consultant-select"
                            inputProps={{
                                label: 'Consultant',
                                InputLabelProps: {
                                    htmlFor: 'consultant-select',
                                    shrink: true,
                                },
                            }}
                            placeholder={t("orders_manager_consultant_search_label")}
                            options={consultantOptions}
                            value={selectedConsultant}
                            onChange={this.handleSelectConsultant}
                        />
                        {selectedConsultant && <Paper className={classes.searchBox}>
                            <InputBase
                                className={classes.input}
                                placeholder={t("orders_search_label")}
                                inputProps={{ 'aria-label': t("orders_search_label") }}
                                value={search}
                                onChange={(event) => { this.setState({ search: event.target.value }) }}
                                onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                        this.handleSearch();
                                    }
                                }}
                            />
                            <IconButton className={classes.iconButton} aria-label="search" onClick={this.handleSearch}>
                                <SearchIcon />
                            </IconButton>
                        </Paper>}
                    </Paper>
                </Container>
                {selectedConsultant && <Container maxWidth={"xl"}>
                    <Paper className={classes.actionsContainer}>
                        <Button color="primary" variant="contained" className={classes.addButton} onClick={this.addOrderClicked}>{t("order_manager_add_order")}</Button>
                    </Paper>
                </Container>}
                {selectedConsultant && <Container maxWidth={"xl"}>
                    <Paper className={classes.tableContainer}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('orders_grid_po_date')}</TableCell>
                                    <TableCell>{t('orders_grid_po_number')}</TableCell>
                                    <TableCell>{t('orders_grid_company')}</TableCell>
                                    <TableCell>{t('orders_grid_product')}</TableCell>
                                    <TableCell>{t('orders_grid_unit_price')}</TableCell>
                                    <TableCell>{t('orders_grid_quantity')}</TableCell>
                                    <TableCell>{t('orders_grid_total_sales')}</TableCell>
                                    <TableCell>{t('orders_grid_commission')}</TableCell>
                                    <TableCell>{t('orders_grid_payment_status')}</TableCell>
                                    <TableCell>{t('orders_grid_commission_status')}</TableCell>
                                    <TableCell align="right">{t('orders_grid_actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders.map(order => (
                                    <TableRow key={order.id}>
                                        <TableCell component="th" scope="row">{moment(order.poDate).format("MM/DD/YYYY")}</TableCell>
                                        <TableCell >{order.poNumber}</TableCell>
                                        <TableCell>{order.company}</TableCell>
                                        <TableCell>{order.product}</TableCell>
                                        <TableCell>
                                            <CurrencyFormat value={order.unitPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                        </TableCell>
                                        <TableCell>{order.qty}</TableCell>
                                        <TableCell>
                                            <CurrencyFormat value={order.totalSale} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                        </TableCell>
                                        <TableCell>
                                            <CurrencyFormat value={order.commission} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                        </TableCell>
                                        <TableCell>{t(`orders_dialog_payment_status_${order.paymentStatus}`).toUpperCase()}</TableCell>
                                        <TableCell>{t(`orders_dialog_commmission_status_${order.commissionStatus}`).toUpperCase()}</TableCell>
                                        <TableCell align="right">
                                            <Button size="small" variant="contained" className={classes.button} onClick={() => this.updateOrderClicked(order)}>
                                                {t('oders_manager_update_button_text')}
                                            </Button>
                                            <Button size="small" color="secondary" variant="contained" className={classes.deleteButton} onClick={() => { this.openConfirmDialog(order.id) }}>
                                                {t('oders_manager_delete_button_text')}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell align={"right"}><strong>{t('orders_grid_totals')}</strong></TableCell>
                                    <TableCell>
                                        <strong>
                                            <CurrencyFormat value={ordersMeta.totalCommission} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                        </strong>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Paper>
                </Container>}
                <Dialog disableBackdropClick={true} open={orderDialogOpen} onClose={this.closeOrderDialog} aria-labelledby="consultant-dialog-title" maxWidth={"md"} fullWidth={true}>
                    <DialogTitle id="consultant-dialog-title">{orderDialogEdit ? t('orders_dialog_title_edit_order') : t('orders_dialog_title_create_order')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {orderDialogEdit ? t('orders_dialog_text_edit_order') : t('orders_dialog_text_create_order')} {selectedConsultant ? selectedConsultant.value.fullName : ''}
                        </DialogContentText>
                        <Grid container spacing={1} justify="space-around">
                            <Grid item xs>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="none"
                                        style={{
                                            marginTop: 5
                                        }}
                                        label={t('orders_dialog_po_date_label')}
                                        value={orderPoDate}
                                        onChange={this.handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        fullWidth={true}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs>
                                <TextField autoFocus margin="dense" name="orderCompany" id="orderCompany" label={t('orders_dialog_company_label')} type="text" fullWidth onChange={this.handleFormChange} value={orderCompany} />
                            </Grid>
                            <Grid item xs>
                                <TextField margin="dense" name="orderPoNumber" id="orderPoNumber" label={t('orders_dialog_po_number_label')} type="text" fullWidth onChange={this.handleFormChange} value={orderPoNumber} />
                            </Grid>
                            <Grid item xs>
                                <TextField margin="dense" name="orderProduct" id="orderProduct" label={t('orders_dialog_product_label')} type="text" fullWidth onChange={this.handleFormChange} value={orderProduct} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justify="space-around"  >
                            <Grid item xs>
                                <TextField margin="dense" name="orderUnitPrice" id="orderUnitPrice" label={t('orders_dialog_unit_price_label')} type="number" fullWidth onChange={this.handleFormChange} value={orderUnitPrice} />
                            </Grid>
                            <Grid item xs>
                                <TextField margin="dense" name="orderQuantity" id="orderQuantity" label={t('orders_dialog_quantity_label')} type="number" fullWidth onChange={this.handleFormChange} value={orderQuantity} />
                            </Grid>
                            <Grid item xs>
                                <TextField margin="dense" name="orderTotalSale" id="orderTotalSale" label={t('orders_dialog_total_sales_label')} type="number" fullWidth onChange={this.handleFormChange} value={orderTotalSale} />
                            </Grid>

                            <Grid item xs>
                                <TextField margin="dense" name="orderCommissionPct" id="orderCommissionPct" label={t('orders_dialog_commission_percentage_label')} type="number" fullWidth onChange={this.handleFormChange} value={orderCommissionPct} />
                            </Grid>
                            <Grid item xs>
                                <TextField margin="dense" name="orderTotalCommission" id="orderTotalCommission" label={t('orders_dialog_commission_label')} type="number" fullWidth onChange={this.handleFormChange} value={orderTotalCommission} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justify="space-around" >
                            <Grid item xs>
                                <FormControl fullWidth={true} style={{
                                    marginTop: 5
                                }}>
                                    <InputLabel id="orderPaymentStatus">{t('orders_dialog_payment_status_label')}</InputLabel>
                                    <MaterialSelect id="orderPaymentStatus" name="orderPaymentStatus" value={orderPaymentStatus} onChange={this.handleFormChange}>
                                        <MenuItem value={'unpaid'}>{t('orders_dialog_payment_status_unpaid')}</MenuItem>
                                        <MenuItem value={'paid'}>{t('orders_dialog_payment_status_paid')}</MenuItem>
                                        <MenuItem value={'overdue'}>{t('orders_dialog_payment_status_overdue')}</MenuItem>
                                    </MaterialSelect>
                                </FormControl>
                            </Grid>
                            <Grid item xs>
                                <FormControl fullWidth={true} style={{
                                    marginTop: 5
                                }}>
                                    <InputLabel id="orderPaymentStatus">{t('orders_dialog_commmission_status_label')}</InputLabel>
                                    <MaterialSelect id="orderCommissionStatus" name="orderCommissionStatus" value={orderCommissionStatus} onChange={this.handleFormChange}>
                                        <MenuItem value={'unpaid'}>{t('orders_dialog_commmission_status_unpaid')}</MenuItem>
                                        <MenuItem value={'paid'}>{t('orders_dialog_commmission_status_paid')}</MenuItem>
                                        <MenuItem value={'hold'}>{t('orders_dialog_commmission_status_hold')}</MenuItem>
                                    </MaterialSelect>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeOrderDialog}>
                            {t('orders_dialog_button_close')}
                        </Button>
                        <Button onClick={() => {
                            orderDialogEdit ? this.editOrder() : this.addOrder()
                        }} color="primary">
                            {orderDialogEdit ? t('orders_dialog_button_edit') : t('orders_dialog_button_create')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog disableBackdropClick={true} open={confirmDeleteOpen} onClose={this.closeConfirmDialog} aria-labelledby="consultant-dialog-title">
                    <DialogTitle id="consultant-dialog-title">{t('confirm_delete_dialog_title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this order?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeConfirmDialog}>
                            {t('confirm_delete_dialog_button_cancel')}
                        </Button>
                        <Button onClick={this.deleteOrder} color="primary">
                            {t('confirm_delete_dialog_button_delete')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(withTranslation()(ConsultantsManager));