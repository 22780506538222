import React, { Component } from 'react';
import CurrencyFormat from 'react-currency-format';
import { withStyles } from '@material-ui/core/styles';
import { Search as SearchIcon } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';

import {
    Container,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    InputBase,
    IconButton,
    TableFooter
} from '@material-ui/core';
import ErrorManager from '../../../managers/errorManager';
import DataService from '../../../services/dataService';
import moment from 'moment';


const styles = theme => ({

    tableContainer: {
        marginTop: theme.spacing(3),
        overflowX: 'scroll'
    },
    table: {
        minWidth: 750,
    },
    searchBox: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        marginTop: 15
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
});


class ConsultantsManager extends Component {

    dataService;

    constructor(props) {
        super(props);

        this.dataService = new DataService();
        this.state = {
            orders: [],
            ordersMeta: {},
            page: 1,
            pageSize: 9999,
            search: '',

        }
    }

    componentDidMount() {
        this.getOrdersForConsultant();
    }


    getOrdersForConsultant = async () => {
        try {
            let response = await this.dataService.getOrders(this.state.page, this.state.pageSize, this.state.search);
            this.setState({
                orders: response.orders,
                ordersMeta: response.meta
            });
        }
        catch (error) {
            ErrorManager.getInstance().broadcastError(error.message)
        }
    }

    handleSearch = async () => {
        await this.getOrdersForConsultant();
    }

    render() {
        console.log(Object.keys(this.props))
        const { classes, t } = this.props;
        const {
            orders,
            ordersMeta,
            search
        } = this.state;

        return (
            <div>
                <Container>
                    <Paper className={classes.searchBox}>
                        <InputBase
                            className={classes.input}
                            placeholder={t("orders_search_label")}
                            inputProps={{ 'aria-label': t("orders_search_label") }}
                            value={search}
                            onChange={(event) => { this.setState({ search: event.target.value }) }}
                            onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    this.handleSearch();
                                }
                            }}
                        />
                        <IconButton className={classes.iconButton} aria-label="search" onClick={this.handleSearch}>
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Container>
                <Container>
                    <Paper className={classes.tableContainer}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('orders_grid_po_date')}</TableCell>
                                    <TableCell>{t('orders_grid_po_number')}</TableCell>
                                    <TableCell>{t('orders_grid_company')}</TableCell>
                                    <TableCell>{t('orders_grid_product')}</TableCell>
                                    <TableCell>{t('orders_grid_unit_price')}</TableCell>
                                    <TableCell>{t('orders_grid_quantity')}</TableCell>
                                    <TableCell>{t('orders_grid_total_sales')}</TableCell>
                                    <TableCell>{t('orders_grid_commission')}</TableCell>
                                    <TableCell>{t('orders_grid_payment_status')}</TableCell>
                                    <TableCell>{t('orders_grid_commission_status')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders.map(order => (
                                    <TableRow key={order.id}>
                                        <TableCell component="th" scope="row">{moment(order.poDate).format("MM/DD/YYYY")}</TableCell>
                                        <TableCell >{order.poNumber}</TableCell>
                                        <TableCell>{order.company}</TableCell>
                                        <TableCell>{order.product}</TableCell>
                                        <TableCell>
                                            <CurrencyFormat value={order.unitPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                        </TableCell>
                                        <TableCell>{order.qty}</TableCell>
                                        <TableCell>
                                            <CurrencyFormat value={order.totalSale} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                        </TableCell>
                                        <TableCell>
                                            <CurrencyFormat value={order.commission} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                        </TableCell>
                                        <TableCell>{t(`orders_dialog_payment_status_${order.paymentStatus}`).toUpperCase()}</TableCell>
                                        <TableCell>{t(`orders_dialog_commmission_status_${order.commissionStatus}`).toUpperCase()}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell align={"right"}><strong>{t('orders_grid_totals')}</strong></TableCell>
                                    <TableCell>
                                        <strong>
                                            <CurrencyFormat value={ordersMeta.totalCommission} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                        </strong>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Paper>
                </Container>
            </div>
        );
    }
}

export default withStyles(styles)(withTranslation()(ConsultantsManager));