import Base from './serviceBase';

class AuthService extends Base {
  verify() {
    return this.jsonRequest('verify', 'GET');
  }
  login(userName, password) {
    return this.jsonRequest('login', 'POST', { userName, password });
  }
  register(userName, password) {
    return this.jsonRequest('register', 'POST', { userName, password });
  }
  changePassword(currentPassword, newPassword) {
    return this.jsonRequest('users/password', 'PUT', { currentPassword, newPassword });
  }
}

export default AuthService;
