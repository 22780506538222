import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import AuthenticatedPage from '../../components/authenticatedPage';
import AuthManager from '../../managers/authManager';
import NavBar from '../../components/navBar';
import ErrorHandler from '../../components/errorHandler';
import './index.css';
class LandingPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loginElement: null,
            passwordDialogOpen: false
        }
    }

    logOut() {
        AuthManager.getInstance().logOut();
    }

    userProfileClicked = (event) => {
        this.setState({
            loginElement: event.currentTarget
        })
    }

    userProfileClosed = () => {
        this.setState({
            loginElement: null
        })
    }

    componentDidMount() {
        const leftArrowBox = document.getElementsByClassName('leftArrowBox');
        const rightArrowBox = document.getElementsByClassName('rightArrowBox');
        const carControlImageContainerInside = document.getElementsByClassName('carControlImageContainerInside');


        let topCarouselLeft = 0;
        let topCarMobLeft = 0;

        function arrowLeft() {
            if (window.innerWidth > window.innerHeight) {
                if (topCarouselLeft < -1) {
                    topCarouselLeft += 50;
                    carControlImageContainerInside[0].style.left = topCarouselLeft + '%';
                } else {
                    topCarouselLeft = -400;
                    carControlImageContainerInside[0].style.transition = '0s';
                    setTimeout(function () {
                        carControlImageContainerInside[0].style.left = topCarouselLeft + '%';
                        setTimeout(function () {
                            carControlImageContainerInside[0].style.transition = 'all 1s ease';
                            topCarouselLeft += 50;
                            carControlImageContainerInside[0].style.left = topCarouselLeft + '%';
                        }, 100);
                    }, 100);
                }
            } else {
                if (topCarMobLeft < -1) {
                    topCarMobLeft += 100;
                    carControlImageContainerInside[0].style.left = topCarMobLeft + '%';
                } else {
                    topCarMobLeft = -800;
                    carControlImageContainerInside[0].style.transition = '0s';
                    setTimeout(function () {
                        carControlImageContainerInside[0].style.left = topCarMobLeft + '%';
                        setTimeout(function () {
                            carControlImageContainerInside[0].style.transition = 'all 1s ease';
                            topCarMobLeft += 100;
                            carControlImageContainerInside[0].style.left = topCarMobLeft + '%';
                        }, 100);
                    }, 100);
                }
            }
        };

        function arrowRight() {
            if (window.innerWidth > window.innerHeight) {
                if (topCarouselLeft > -399) {
                    topCarouselLeft -= 50;
                    carControlImageContainerInside[0].style.left = topCarouselLeft + '%';
                } else {
                    topCarouselLeft = 0;
                    carControlImageContainerInside[0].style.transition = '0s';
                    setTimeout(function () {
                        carControlImageContainerInside[0].style.left = topCarouselLeft + '%';
                        setTimeout(function () {
                            carControlImageContainerInside[0].style.transition = 'all 1s ease';
                            topCarouselLeft -= 50;
                            carControlImageContainerInside[0].style.left = topCarouselLeft + '%';
                        }, 100);
                    }, 100);
                }
            } else {
                if (topCarMobLeft > -899) {
                    topCarMobLeft -= 100;
                    carControlImageContainerInside[0].style.left = topCarMobLeft + '%';
                } else {
                    topCarMobLeft = -100;
                    carControlImageContainerInside[0].style.transition = '0s';
                    setTimeout(function () {
                        carControlImageContainerInside[0].style.left = topCarMobLeft + '%';
                        setTimeout(function () {
                            carControlImageContainerInside[0].style.transition = 'all 1s ease';
                            topCarMobLeft -= 100;
                            carControlImageContainerInside[0].style.left = topCarMobLeft + '%';
                        }, 100);
                    }, 100);
                }
            }
        };


        leftArrowBox[0].addEventListener('click', () => {
            arrowLeft();
        });

        rightArrowBox[0].addEventListener('click', () => {
            arrowRight();
        });

        // order commission relationship
        const selectionBox = document.getElementsByClassName('selectionBox');
        let carTextMoving = document.getElementsByClassName('carTextMoving');
        const selectionUnderline = document.getElementsByClassName('selectionUnderline');

        function sectionBoxReset() {
            for (let i = 0; i < selectionBox.length; i++) {
                selectionBox[i].style.color = '#b3b3b3';
                selectionUnderline[i].style.width = '0vw';
            }
        }

        selectionBox[0].addEventListener('click', (e) => {
            sectionBoxReset();
            e.currentTarget.children[1].style.width = '72%';
            carTextMoving[0].style.left = '0%';
            e.currentTarget.style.color = 'red';
        });

        selectionBox[1].addEventListener('click', (e) => {
            sectionBoxReset();
            e.currentTarget.children[1].style.width = '82%';
            carTextMoving[0].style.left = '-100%';
            e.currentTarget.style.color = 'red';
        });

        selectionBox[2].addEventListener('click', (e) => {
            sectionBoxReset();
            e.currentTarget.children[1].style.width = '82%';
            carTextMoving[0].style.left = '-200%';
            e.currentTarget.style.color = 'red';
        });

        // mob car commission
        const commissionleftArrowBox = document.getElementsByClassName('commissionleftArrowBox');
        const titleContainer = document.getElementsByClassName('titleContainer');
        carTextMoving = document.getElementsByClassName('carTextMoving');

        let commissionTitleContainerLeft = 0;
        let commissionTextContainerLeft = -100;

        commissionleftArrowBox[0].addEventListener('click', () => {
            if (commissionTitleContainerLeft < 99) {
                commissionTitleContainerLeft += 100;
                commissionTextContainerLeft += 100;
            }
            titleContainer[0].style.left = commissionTitleContainerLeft + '%';;
            carTextMoving[0].style.left = commissionTextContainerLeft + '%';
        });

        commissionleftArrowBox[1].addEventListener('click', () => {
            if (commissionTitleContainerLeft > -99) {
                commissionTitleContainerLeft -= 100;
                commissionTextContainerLeft -= 100;
            }
            titleContainer[0].style.left = commissionTitleContainerLeft + '%';;
            carTextMoving[0].style.left = commissionTextContainerLeft + '%';
        });

        // window events;
        window.addEventListener('resize', () => {
            // reset  the car for desk/mob
            try {
                carControlImageContainerInside[0].style.left = 0;
            } catch (error) {
                console.log(`resize event error`);
            }
        });

    }

    render() {
        return (
            <AuthenticatedPage history={this.props.history}>
                <div className="landing">
                    <header>
                        <NavBar history={this.props.history} />
                        <img className="baner" src="/images/baner.jpg" alt="" />
                        <h2 className="welcome">WELCOME</h2>
                        <div className="topBGcontainer">
                            <picture className="">
                                <source media="(orientation:landscape)" srcSet="/images/TopBG.svg" />
                                <source media="(orientation:portrait)" srcSet="/images/TopBG.svg" />
                                <img className="topBG" src="/images/TopBG.svg" alt="" />
                            </picture>
                        </div>

                        <div className="headerContentContainer">
                            <div className="headerImgContainer">
                                <img className="headerImg" src="/images/headerImg.png" alt="" />
                            </div>
                            <h1>THE NNB CONSULTANT</h1>
                            <div className="headerTextContainer">
                                <div className="textContainerLeft">
                                    <p>Do you have a passion for new and novel ingredients? Or know someone who loves science? Perhaps you or your friends work in the supplement, food or beverage industry? The NNB Consultant program is designed for you.
                                      The NNB Consultant is a new program supported by our team of 100+ scientists across 6+ countries, and backed with our factories and facilities in both China and the United States.
                                      As an NNB Consultant, you will spread the philosophy behind healthy lifestyle and nutritious ingredient to your friends, families and your professional network. By offering your professional knowledge, you will build a trusting relationship with the ingredients we offer,
                and significantly change the lifestyle of those around you.</p>
                                </div>
                                <div className="textContainerRight">
                                    <p>In order for you to succeed, we provide with you with top-notch support our decades of experience in the industry. Our quality control is second to none: we test our actives to assure no adulteration and guarantee our compounds have the fullest strength and efficacy. We have full chain of custody and traceability, so you know exactly where these compounds come from and how they are made - every step is fully transparent.
                In addition, NNB’s ingredients are backed by solid science. Our ingredients can be seen in various stages of studies, trademarks and patents. They are so unique that they provide you with not only a career, but an opportunity to truly make a positive impact on those around you by providing distinctly-competitive advantages, outstanding efficacies and incomparable return-of-investment.</p>
                                </div>
                            </div>
                            <h3>All in all: we succeed when you succeed.</h3>
                        </div>

                    </header>
                    <section className="ingredientsSection" id="downloads" >
                        <div className="carContainer">
                            <picture className="">
                                <source media="(orientation:landscape)" srcSet="/images/carImages/1.jpg" />
                                <source media="(orientation:portrait)" srcSet="/images/mobile/carImages/1.jpg" />
                                <img src="/images/mobile/carImages/1.jpg" alt="" />
                            </picture>
                        </div>

                        <div className="ingredientContent">
                            <div className="ingredientTextBox">
                                <h2>INGREDIENTS</h2>
                                <h3>
                                    NNB Nutrition has amazing ingredients that are some of the hottest and most effective ingredients on the planet.

                                    Click on the ingredients below to download the corresponding whitepaper and flyers.
                                </h3>
                            </div>
                            <div className="carSeparateControlContainer">
                                <div className="leftArrowBox">
                                    <div className="popUpLeftArrowBox">
                                        <div className="popUpLeftArrow"></div>
                                    </div>
                                </div>
                                <div className="carControlImageContainer">
                                    <div className="carControlImageContainerInside">
                                        <div className="carControlBox mitoburn">
                                            <a href="/downloads/MitoBurn.zip"><img src="/images/carImages/carTop/mitoburn.svg" alt="" /></a>
                                            <p>Igniting The Metabolic Fire Within</p>
                                        </div>
                                        <div className="carControlBox nucleoprime">
                                            <a href="/downloads/NucleoPrime.zip"><img src="/images/carImages/carTop/nucleoprime.svg" alt="" /></a>
                                            <p>The Building Blocks To Better Performance</p>
                                        </div>
                                        <div className="carControlBox c8vantage">
                                            <a href="/downloads/C8Vantage.zip"><img src="/images/carImages/carTop/c8vantage.svg" alt="" /></a>
                                            <p>MCTs Evolved</p>
                                        </div>
                                        <div className="carControlBox caloriburn">
                                            <a href="/downloads/CaloriBurn.zip"><img src="/images/carImages/carTop/caloriburn.svg" alt="" /></a>
                                            <p>The Next Gen Calorie Burner</p>
                                        </div>
                                        <div className="carControlBox truseedp">
                                            <a href="/downloads/TruSeed P.zip"><img src="/images/carImages/carTop/truseedp.svg" alt="" /></a>
                                            <p>Naturally Better Protein</p>
                                        </div>
                                        <div className="carControlBox ketovantage">
                                            <a href="/downloads/KetoVantage.zip"><img src="/images/carImages/carTop/ketovantage.svg" alt="" /></a>
                                            <p>Revolutionary Ketones</p>
                                        </div>
                                        <div className="carControlBox glucovantage">
                                            <a href="/downloads/GlucoVantage.zip"><img src="/images/carImages/carTop/glucovantage.svg" alt="" /></a>
                                            <p>The Super Berberine</p>
                                        </div>
                                        <div className="carControlBox mitoprime">
                                            <a href="/downloads/MitoPrime.zip"><img className="mitoprime" src="/images/carImages/carTop/mitoprime.png" alt="" /></a>
                                            <p>The Next Level Antioxidant</p>
                                        </div>
                                        <div className="carControlBox mitoburn">
                                            <a href="/downloads/MitoBurn.zip"><img src="/images/carImages/carTop/mitoburn.svg" alt="" /></a>
                                            <p>Igniting The Metabolic Fire Within</p>
                                        </div>
                                        <div className="carControlBox nucleoprime">
                                            <a href="/downloads/NucleoPrime.zip"><img src="/images/carImages/carTop/nucleoprime.svg" alt="" /></a>
                                            <p>The Building Blocks To Better Performance</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="rightArrowBox">
                                    <div className="popUpLeftArrowBox rightPopArrow">
                                        <div className="popUpLeftArrow"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="buttonContainer">
                                <button onClick={() => { window.location.href = '/downloads/All.zip' }} type="button" name="button">DOWNLOAD ALL</button>
                            </div>
                        </div>
                    </section>
                    <div className="botBGcontainer">
                        <picture className="">
                            <source media="(orientation:landscape)" srcSet="/images/BotBG.svg" />
                            <source media="(orientation:portrait)" srcSet="/images/BotBG.svg" />
                            <img className="botBG" src="/images/BotBG.svg" alt="" />
                        </picture>
                    </div>
                    <section className="commisionSection">
                        <div className="titleContainer">
                            <div className="selectionBox">
                                <h2>ORDER</h2>
                                <div className="selectionUnderline"></div>
                            </div>
                            <div className="selectionBox selected">
                                <h2 >COMMISSION</h2>
                                <div className="selectionUnderline"></div>
                            </div>
                            <div className="selectionBox">
                                <h2>RELATIONSHIP</h2>
                                <div className="selectionUnderline"></div>
                            </div>
                        </div>

                        <div className="carTextBox">
                            <div className="carTextMoving">
                                <div className="textOrder">
                                    <p>NNB treats consultants as a part of the family. For us, we value nothing more thant the relationship we build with you.
                                      Each year, consultants are invited to participate on two funded-trips by NNB so each consultant can continue to grow their professionalism, empower their mission statements, and grow to be stronger human beings. On top of that, NNB provides monthly teleconference calls to keep consultants up-to-date on industry news, ingredient training, and other updates. The total amount of these teleconferences can cumulate to up to 24 hours each year.
                                  For those who’d like to take their career to the next level, NNB is here to offer full-on support. As your consultant career progresses, you will be exposed to different opportunities, such as media events, interviews, and attending major conferences.</p>
                                </div>
                                <div className="textOrder">
                                    <p>As the old Chinese saying goes: you harvest what you've sowed. NNB rewards hardworking consultant with generous commissions on top of your base-pay agreed upon your joining. <br />
                                        Each month, you will receive a commission payment equal to 5% of your production. Commissions are paid for the 1st three years after the initial order (this part needs edit) and is not affected by the termination of the agreement under any circumstances. <br />
                                        As we always say, we want you to succeed, because that is the only way NNB will be able to grow. The consultant position has endless potential - and it is our genuine hope that you are ready to embark on this journey with us.</p>
                                </div>
                                <div className="textOrder">
                                    <p>NNB believes in the competency of our consultant partner. As a consultant, you are fully accountabe for the creation, tracking, modification and deletion of any orders you process. There is no limit on how much you could achieve as a consultant - however, you are responsible for your own success.
                                        But worry not! We fully understand the importance of pairing sharp professionals with efficient tools, as even the most splendid housewives could not make bread with out flour. At this point, we are dedicating oursevles to develop a consultant portal that will allow you to automate your workflow and see all order status in one place.
                                    Stay tuned - more information on the order management system coming soon!</p>
                                </div>
                            </div>
                            <div className="commissionArrowsContainer">
                                <div className=" commissionleftArrowBox">
                                    <div className=" commissionpopUpLeftArrowBox">
                                        <div className=" commissionpopUpLeftArrow"></div>
                                    </div>
                                </div>
                                <div className=" commissionleftArrowBox commissionrightPopArrow">
                                    <div className=" commissionpopUpLeftArrowBox">
                                        <div className=" commissionpopUpLeftArrow"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="featuresSection">
                        <div className="featuredTopBgContainer">
                            <picture className="">
                                <source media="(orientation:landscape)" srcSet="/images/featuresTopbg.svg" />
                                <source media="(orientation:portrait)" srcSet="/images/featuresTopbg.svg" />
                                <img className="featuresTopBg" src="/images/featuresTopbg.svg" alt="" />
                            </picture>

                        </div>
                        <h2>FEATURES</h2>
                        <div className="featuredContainer">
                            <div className="featuredBox" onClick={() => this.props.history.push('/dashboard/profile')}>
                                <img src="/images/featuredSection/manage.svg" alt="" />
                                <h3>Manage your consultant profile at your fingertip</h3>
                            </div>
                            <div className="featuredBox" onClick={() => this.props.history.push('/dashboard/orders')}>
                                <img src="/images/featuredSection/track.svg" alt="" />
                                <h3>Track all orders & commission in one place easily</h3>
                            </div>
                            <div className="featuredBox">
                                <Link to="/#downloads" style={{
                                    textDecoration: "none",
                                    color: "#16203f"
                                }}>
                                    <img src="/images/featuredSection/commission.svg" alt="" />
                                    <h3>Prepare yourself with downloadable product information</h3>
                                </Link>
                            </div>
                        </div>

                        <picture className="">
                            <source media="(orientation:landscape)" srcSet="/images/featuredSection/featuredBotBg.png" />
                            <source media="(orientation:portrait)" srcSet="/images/mobile/featuredSection/featuredBotBg.png" />
                            <img className="featuredBotBg" src="/images/mobile/featuredSection/featuredBotBg.png" alt="" />
                        </picture>

                        <p className="rightsReserved">&copy;NNB - ALL RIGHTS RESERVED</p>
                    </section>
                </div>
                <ErrorHandler />
            </AuthenticatedPage >
        );
    }
}

export default LandingPage;