import Base from './serviceBase';

class DataService extends Base {
  getOrders(page, pageSize, search) {
    let url = `orders`;
    let addedQs = false;
    if (page || pageSize || search) {
      url += '?';
    }
    if (page) {
      url += `${addedQs ? '&' : ''}page=${page}`;
      addedQs = true;
    }
    if (pageSize) {
      url += `${addedQs ? '&' : ''}pageSize=${pageSize}`;
      addedQs = true;
    }
    if (search) {
      url += `${addedQs ? '&' : ''}search=${encodeURI(search)}`;
    }
    return this.jsonRequest(url, 'GET');
  }

  getProfile() {
    return this.jsonRequest('user/profile', 'GET');
  }

  updateProfile(profile) {
    return this.jsonRequest('user/profile', 'PUT', profile);
  }
}

export default DataService;
