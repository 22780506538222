import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import jwt from 'jsonwebtoken';

import AuthenticatedPage from '../../components/authenticatedPage';
import AuthManager from '../../managers/authManager';
import UserManager from './tabs/userManager';
import OrdersManager from './tabs/ordersManager';
import Profile from './tabs/profile';
import Orders from './tabs/orders';
import NavBar from '../../components/navBar';
import ErrorHandler from '../../components/errorHandler';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    nnbLogo: {
        width: 50
    },
    spacer: {
        flexGrow: 1
    },
    appBar: {
        background: '#E62510'
    },
    tabBar: {
        background: '#F3F3F3',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    tabIndicator: {
        background: '#E62510'
    },
    container: {
        marginTop: 15
    }
})

class LandingPage extends Component {


    constructor(props) {
        super(props);

        this.state = {
            roles: jwt.decode(AuthManager.getInstance().bearerToken).roles,
            selectedTab: 0
        };
    }

    componentDidMount() {
        this.props.history.listen((location, action) => {
            let selectedTab = location.pathname.split('/')[2] || 'default'
            this.updateTab(selectedTab);
        });

        this.updateTab();
    }

    updateTab = (name = "") => {
        this.setState({
            selectedTab: this.tabNameToIndex(name || this.props.match.params.tab),
        });
    }

    tabNameToIndex = (tabName) => {
        const { roles } = this.state;

        if (roles.includes('Admin')) {
            switch (tabName) {
                case "consultants":
                    return 0;
                case "admins":
                    return 1;
                case "orders":
                    return 2;
                case "profile":
                    return 3;
                default:
                    return 0;
            }
        }
        else if (roles.includes('Consultant')) {
            switch (tabName) {
                case "orders":
                    return 0;
                case "profile":
                    return 1;
                default:
                    return 0;
            }
        }
    }

    logOut() {
        AuthManager.getInstance().logOut();
    }

    handleChange = (event, newValue) => {
        this.setState({ selectedTab: newValue });
    };

    render() {
        const { classes, t } = this.props;
        const { selectedTab, roles } = this.state;


        console.log(roles)

        return (
            <AuthenticatedPage history={this.props.history}>
                {roles && roles.length > 0 && <div className={classes.root}>
                    <NavBar history={this.props.history} />
                    {roles.includes('Admin') && <div>
                        <AppBar position="static">
                            <Tabs
                                value={selectedTab}
                                onChange={this.handleChange}
                                aria-label="simple tabs example"
                                className={classes.tabBar}
                                TabIndicatorProps={{ className: classes.tabIndicator }}
                                variant="scrollable"
                                scrollButtons="auto">
                                <Tab label={t("tab_name_consultantsManager")} />
                                <Tab label={t("tab_name_admin_manager")} />
                                <Tab label={t("tab_name_ordersManager")} />
                                <Tab label={t("tab_name_profile")} />
                            </Tabs>
                        </AppBar>
                        {selectedTab === 0 && <UserManager userType={"consultant"} />}
                        {selectedTab === 1 && <UserManager userType={"admin"} />}
                        {selectedTab === 2 && <OrdersManager />}
                        {selectedTab === 3 && <Profile />}
                    </div>}
                    {roles.includes('Consultant') && <div>
                        <AppBar position="static">
                            <Tabs
                                value={selectedTab}
                                onChange={this.handleChange}
                                aria-label="simple tabs example"
                                className={classes.tabBar}
                                TabIndicatorProps={{ className: classes.tabIndicator }}
                                variant="scrollable"
                                scrollButtons="auto">
                                <Tab label={t('tab_name_orders')} />
                                <Tab label={t('tab_name_profile')} />
                            </Tabs>
                        </AppBar>
                        {selectedTab === 0 && <Orders />}
                        {selectedTab === 1 && <Profile />}
                    </div>}
                </div>}
                <ErrorHandler />
            </AuthenticatedPage>
        );
    }
}

export default withStyles(styles)(withTranslation()(LandingPage));