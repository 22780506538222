import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { FilterList as FilterListIcon } from '@material-ui/icons';
import {
    Container,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    Grid,
    Checkbox,
    FormControlLabel,
    Toolbar,
    Typography,
    Tooltip,
    IconButton,
    Popper,
    ClickAwayListener,
    MenuList,
    MenuItem
} from '@material-ui/core';
import AdminService from '../../../services/adminService';
import ErrorManager from '../../../managers/errorManager';


const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    button: {
        marginLeft: 10
    },
    passwordButton: {
        marginLeft: 10,
        backgroundColor: '#16203f',
    },
    table: {
        minWidth: 750,
    },
    toolbar: {
        '& .title': {
            flex: '1 1 100%'
        },
        '& .button': {
            float: 'right'
        },
    },
    addFab: {
        margin: theme.spacing(1),
        position: 'absolute',
        bottom: '35px',
        right: '35px',
        backgroundColor: '#16203f',
    },
    actionsContainer: {
        padding: 15,
        display: 'flex',
        alignItems: 'center',
        marginTop: 15
    },
    addButton: {
        backgroundColor: '#16203f',
    },
});

class UsersManager extends Component {

    userType = "consultant";
    adminService;

    constructor(props) {
        super(props);

        if (props.userType) {
            this.userType = props.userType;
        }

        this.adminService = new AdminService();

        this.state = {
            users: [],
            addDialogOpen: false,
            addUserUserName: '',
            addUserFullName: '',
            addUserEmail: '',
            addUserAddress: '',
            addUserPhoneNumber: '',
            addUserStatus: '',
            addUserPassword: '',
            addUserIsActive: true,
            updateDialogOpen: false,
            updateUserId: '',
            updateUserFullName: '',
            updateUserAddress: '',
            upateConsultantPhoneNumber: '',
            updateUserEmail: '',
            updateUserStatus: '',
            updateUserIsActive: false,
            passwordDialogOpen: false,
            passwordConsultantId: '',
            newPassword: '',
            filterMenuOpen: false,
            filterMenuAnchor: null,
            showAll: false
        }
    }

    componentDidMount() {
        this.getUsers();
    }

    handleSelectionChanged = (name) => (event) => {
        this.setState({
            [name]: event.target.checked
        })
    }

    handleFormChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    getUsers = async () => {
        try {
            let response = {};
            let { showAll } = this.state;

            if (this.userType === "admin") {
                response = await this.adminService.getAdmins(showAll);
                this.setState({
                    users: response.admins
                });
            }
            else {
                response = await this.adminService.getConsultants(showAll);
                this.setState({
                    users: response.consultants
                });
            }

        }
        catch (error) {
            ErrorManager.getInstance().broadcastError(error.message)
        }
    }

    addUser = async () => {
        let { addUserUserName, addUserFullName, addUserEmail, addUserPassword, addUserStatus, addUserPhoneNumber, addUserIsActive, addUserAddress } = this.state;
        try {
            if (this.userType === "admin") {
                await this.adminService.addAdmin({
                    userName: addUserUserName,
                    password: addUserPassword,
                    email: addUserEmail,
                    fullName: addUserFullName,
                    status: addUserStatus,
                    phoneNumber: addUserPhoneNumber,
                    address: addUserAddress,
                    isActive: addUserIsActive
                });
            }
            else {
                await this.adminService.addConsultant({
                    userName: addUserUserName,
                    password: addUserPassword,
                    email: addUserEmail,
                    fullName: addUserFullName,
                    status: addUserStatus,
                    phoneNumber: addUserPhoneNumber,
                    address: addUserAddress,
                    isActive: addUserIsActive
                });

            }

            this.setState({
                addUserUserName: '',
                addUserFullName: '',
                addUserEmail: '',
                addUserAddress: '',
                addUserPhoneNumber: '',
                addUserStatus: '',
                addUserPassword: '',
                addUserIsActive: true,
                addDialogOpen: false
            })

            this.getUsers();
        }
        catch (error) {
            ErrorManager.getInstance().broadcastError(error.message)
        }
    }

    updateUser = async () => {
        let {
            updateUserFullName,
            updateUserEmail,
            updateUserStatus,
            updateUserId,
            updateUserAddress,
            updateUserPhoneNumber,
            updateUserIsActive
        } = this.state;

        try {
            if (this.userType === "admin") {
                await this.adminService.updateAdmin({
                    id: updateUserId,
                    email: updateUserEmail,
                    fullName: updateUserFullName,
                    status: updateUserStatus,
                    isActive: updateUserIsActive,
                    phoneNumber: updateUserPhoneNumber,
                    address: updateUserAddress
                });
            }
            else {
                await this.adminService.updateConsultant({
                    id: updateUserId,
                    email: updateUserEmail,
                    fullName: updateUserFullName,
                    status: updateUserStatus,
                    isActive: updateUserIsActive,
                    phoneNumber: updateUserPhoneNumber,
                    address: updateUserAddress
                });
            }
            this.setState({
                updateDialogOpen: false,
                updateUserId: '',
                updateUserFullName: '',
                updateUserEmail: '',
                updateUserStatus: '',
                updateUserAddress: '',
                updateUserPhoneNumber: '',
                updateUserIsActive: false
            })
            this.getUsers();
        }
        catch (error) {
            ErrorManager.getInstance().broadcastError(error.message)
        }
    }

    openAddUser = () => {
        this.setState({
            addDialogOpen: true
        });
    };

    closeAddUser = () => {
        this.setState({
            addDialogOpen: false
        });
    };

    openUpdateUser = (selectedConsultant) => {
        this.setState({
            updateUserId: selectedConsultant.id,
            updateUserFullName: selectedConsultant.fullName,
            updateUserEmail: selectedConsultant.email,
            updateUserStatus: selectedConsultant.status,
            updateUserIsActive: selectedConsultant.isActive,
            updateUserAddress: selectedConsultant.address,
            updateUserPhoneNumber: selectedConsultant.phoneNumber,
            updateDialogOpen: true
        });
    };

    closeUpdateUser = () => {
        this.setState({
            updateDialogOpen: false
        });
    };


    openUpdatePassword = (id) => {
        this.setState({
            passwordConsultantId: id,
            passwordDialogOpen: true
        });
    };

    closeUpdatePassword = () => {
        this.setState({
            passwordConsultantId: '',
            passwordDialogOpen: false
        });
    };

    updatePassword = async () => {
        let {
            passwordConsultantId,
            newPassword
        } = this.state;

        try {
            await this.adminService.updatePassword({
                newPassword,
                consultantUserId: passwordConsultantId
            });

            this.setState({
                passwordConsultantId: '',
                newPassword: '',
                passwordDialogOpen: false,
            });

            this.getUsers();
        }
        catch (error) {
            ErrorManager.getInstance().broadcastError(error.message)
        }
    }

    openFilterMenu = (event) => {
        this.setState({ filterMenuOpen: true, filterMenuAnchor: event.currentTarget });
    }

    closeFilterMenu = () => {
        this.setState({ filterMenuOpen: false, filterMenuAnchor: null });
    }

    toggleShowAll = async (showAll) => {
        this.setState({ showAll }, () => {
            this.getUsers();
        });
        this.closeFilterMenu();
    }


    render() {
        const { classes, t } = this.props;
        const {
            users,
            addDialogOpen,
            updateDialogOpen,
            updateUserFullName,
            updateUserEmail,
            updateUserStatus,
            updateUserIsActive,
            updateUserPhoneNumber,
            updateUserAddress,
            passwordDialogOpen,
            filterMenuOpen,
            filterMenuAnchor,
            showAll
        } = this.state;

        return (
            <div>
                <Container maxWidth={"xl"}>
                    <Paper className={classes.actionsContainer}>
                        <Button color="primary" variant="contained" className={classes.addButton} onClick={this.openAddUser}>{this.userType === "admin" ? t("user_manager_add_admin") : t("user_manager_add_consultant")}</Button>
                    </Paper>
                </Container>
                <Container maxWidth={"xl"} fullWidth={true}>
                    <Paper className={classes.root}>
                        <Toolbar className={classes.toolbar}>
                            <Typography className={"title"} variant="h6">
                                {this.userType === "admin" ? t('Admin') : t('Consultant')}
                            </Typography>
                            <IconButton aria-label="filter list" onClick={this.openFilterMenu}>
                                <FilterListIcon />
                            </IconButton>
                            <Popper open={filterMenuOpen} anchorEl={filterMenuAnchor} transition>
                                <Paper>
                                    <ClickAwayListener onClickAway={this.closeFilterMenu}>
                                        <MenuList autoFocusItem={filterMenuOpen}>
                                            {showAll || <MenuItem onClick={() => this.toggleShowAll(true)}>Show All</MenuItem>}
                                            {showAll && <MenuItem onClick={() => this.toggleShowAll(false)}>Show Active</MenuItem>}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Popper>
                        </Toolbar>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('user_manager_table_userName')}</TableCell>
                                    <TableCell>{t('user_manager_table_fullName')}</TableCell>
                                    <TableCell>{t('user_manager_table_email')}</TableCell>
                                    <TableCell>{t('user_manager_table_phone_number')}</TableCell>
                                    <TableCell>{t('user_manager_table_address')}</TableCell>
                                    <TableCell>{t('user_manager_table_status')}</TableCell>
                                    <TableCell>{t('user_manager_table_is_active')}</TableCell>
                                    <TableCell align="right">{t('user_manager_table_actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map(user => (
                                    <TableRow key={user.id}>
                                        <TableCell component="th" scope="row">{user.userName}</TableCell>
                                        <TableCell >{user.fullName}</TableCell>
                                        <TableCell >{user.email}</TableCell>
                                        <TableCell >{user.phoneNumber}</TableCell>
                                        <TableCell >{user.address}</TableCell>
                                        <TableCell >{user.status}</TableCell>
                                        <TableCell >{user.isActive ? t("is_active_result_yes") : t("is_active_result_no")}</TableCell>
                                        <TableCell align="right" >
                                            <Button size="small" color="primary" variant="contained" className={classes.passwordButton} onClick={() => { this.openUpdatePassword(user.id) }}>
                                                {t('user_manager_table_action_password')}
                                            </Button>
                                            <Button size="small" variant="contained" className={classes.button} onClick={() => { this.openUpdateUser(user) }}>
                                                {t('user_manager_table_action_update')}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Container>
                <Dialog disableBackdropClick={true} open={addDialogOpen} onClose={this.closeAddUser} aria-labelledby="consultant-dialog-title" fullWidth={true}>
                    <DialogTitle id="consultant-dialog-title">{this.userType === "admin" ? t('add_user_dialog_title_admin') : t('add_user_dialog_title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.userType === "admin" ? t('add_user_dialog_message_admin') : t('add_user_dialog_message')}
                        </DialogContentText>
                        <Grid container spacing={1} justify="space-around">
                            <Grid item xs>
                                <TextField autoFocus margin="dense" name="addUserUserName" id="addUserUserName" label={t('add_user_dialog_textbox_userName')} type="text" fullWidth onChange={this.handleFormChange} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justify="space-around">
                            <Grid item xs>
                                <TextField margin="dense" name="addUserFullName" id="fullName" label={t('add_user_dialog_textbox_fullName')} type="text" fullWidth onChange={this.handleFormChange} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justify="space-around">
                            <Grid item xs>
                                <TextField margin="dense" name="addUserEmail" id="email" label={t('add_user_dialog_textbox_email')} type="email" fullWidth onChange={this.handleFormChange} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justify="space-around">
                            <Grid item xs>
                                <TextField margin="dense" name="addUserPhoneNumber" id="addUserPhoneNumber" label={t("add_user_dialog_textbox_phoneNumber")} fullWidth onChange={this.handleFormChange} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justify="space-around">
                            <Grid item xs>
                                <TextField margin="dense" name="addUserAddress" id="addUserAddress" label={t("add_user_dialog_textbox_address")} fullWidth onChange={this.handleFormChange} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justify="space-around">
                            <Grid item xs>
                                <TextField margin="dense" name="addUserPassword" id="password" label={t('add_user_dialog_textbox_password')} type="password" fullWidth onChange={this.handleFormChange} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justify="space-around">
                            <Grid item xs>
                                <TextField margin="dense" name="addUserStatus" id="status" label={t('add_user_dialog_textbox_status')} type="text" fullWidth onChange={this.handleFormChange} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justify="space-around">
                            <Grid item xs>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.addUserIsActive}
                                            onChange={this.handleSelectionChanged('addUserIsActive')}
                                            color="primary"
                                        />
                                    }
                                    label={t("add_user_dialog_is_active")}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeAddUser}>
                            {t('add_user_dialog_button_cancel')}
                        </Button>
                        <Button onClick={this.addUser} color="primary">
                            {t('add_user_dialog_button_add')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog disableBackdropClick={true} open={updateDialogOpen} onClose={this.closeAddUser} aria-labelledby="consultant-dialog-title" fullWidth={true}>
                    <DialogTitle id="consultant-dialog-title">{this.userType === "admin" ? t('update_user_dialog_title_admin') : t('update_user_dialog_title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.userType === "admin" ? t('update_user_dialog_message_admin') : t('update_user_dialog_message')}
                        </DialogContentText>
                        <Grid container spacing={1} justify="space-around">
                            <Grid item xs>
                                <TextField margin="dense" name="updateUserEmail" id="email" label={t('update_user_dialog_textbox_email')} type="email" fullWidth onChange={this.handleFormChange} value={updateUserEmail} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justify="space-around">
                            <Grid item xs>
                                <TextField margin="dense" name="updateUserFullName" id="fullName" label={t('update_user_dialog_textbox_fullName')} type="text" fullWidth onChange={this.handleFormChange} value={updateUserFullName} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justify="space-around">
                            <Grid item xs>
                                <TextField margin="dense" name="updateUserStatus" id="status" label={t('update_user_dialog_textbox_status')} type="text" fullWidth onChange={this.handleFormChange} value={updateUserStatus} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justify="space-around">
                            <Grid item xs>
                                <TextField margin="dense" name="updateUserPhoneNumber" id="updateUserPhoneNumber" label={t("update_user_dialog_textbox_phoneNumber")} type="text" fullWidth onChange={this.handleFormChange} value={updateUserPhoneNumber} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justify="space-around">
                            <Grid item xs>
                                <TextField margin="dense" name="updateUserAddress" id="updateUserAddress" label={t("update_user_dialog_textbox_address")} type="text" fullWidth onChange={this.handleFormChange} value={updateUserAddress} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justify="space-around">
                            <Grid item xs>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={updateUserIsActive}
                                            onChange={this.handleSelectionChanged('updateUserIsActive')}
                                            color="primary"
                                        />
                                    }
                                    label={t("update_user_dialog_is_active")}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeUpdateUser}>
                            {t('update_user_dialog_button_cancel')}
                        </Button>
                        <Button onClick={this.updateUser} color="primary">
                            {t('update_user_dialog_button_update')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog disableBackdropClick={true} open={passwordDialogOpen} onClose={this.closeUpdatePassword} aria-labelledby="update-password-dialog-title" fullWidth={true}>
                    <DialogTitle id="update-password-dialog-title">{t('reset_user_password_dialog_title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('reset_user_password_dialog_message')}
                        </DialogContentText>
                        <TextField autoFocus margin="dense" name="newPassword" id="newPassword" label={t('reset_user_dialog_text_box_newpassword')} type="password" fullWidth onChange={this.handleFormChange} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeUpdatePassword}>
                            {t('reset_user_dialog_button_cancel')}
                        </Button>
                        <Button onClick={this.updatePassword} color="primary">
                            {t('reset_user_dialog_button_update')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(withTranslation()(UsersManager));