import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { Container, Paper, TextField, Grid } from '@material-ui/core';
import AdminService from '../../../services/adminService';
import ErrorManager from '../../../managers/errorManager';
import DataService from '../../../services/dataService';


const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    paper: {
        marginTop: theme.spacing(3),
        padding: 15
    },
});

class Profile extends Component {

    adminService;

    constructor(props) {
        super(props);

        this.adminService = new AdminService();

        this.state = {
            address: '',
            email: '',
            fullName: '',
            phoneNumber: '',
            saveDisabled: true
        }
    }

    componentDidMount() {
        new DataService().getProfile().then((results) => {
            const { user } = results;

            this.setState({
                address: user.address,
                email: user.email,
                fullName: user.fullName,
                phoneNumber: user.phoneNumber
            })
        })
    }

    handleFormChange = (event) => {
        this.setState({
            saveDisabled: false,
            [event.target.name]: event.target.value
        })
    }

    updateInfo = async () => {
        try {
            await new DataService().updateProfile({
                address: this.state.address,
                email: this.state.email,
                fullName: this.state.fullName,
                phoneNumber: this.state.phoneNumber
            });
            this.setState({
                saveDisabled: true
            })
        } catch (error) {
            ErrorManager.getInstance().broadcastError(error.message)
        }
    };

    render() {
        const { classes, t } = this.props;
        const {
            address,
            fullName,
            email,
            phoneNumber,
            saveDisabled
        } = this.state;

        return (
            <Container maxWidth={"md"}>
                <Paper className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="fullName" id="fullName" label={t('profile_tab_fullName')} type="text" fullWidth onChange={this.handleFormChange} value={fullName} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="email" id="email" label={t('profile_tab_email')} type="email" fullWidth onChange={this.handleFormChange} value={email} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="phoneNumber" id="phoneNumber" label={t('profile_tab_phoneNumber')} type="text" fullWidth onChange={this.handleFormChange} value={phoneNumber} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="address" id="address" label={t('profile_tab_address')} type="text" fullWidth onChange={this.handleFormChange} value={address} />
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                            <Button disabled={saveDisabled} variant="contained" color="primary" onClick={this.updateInfo}>
                                {t('profile_tab_action_update')}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Container >
        );
    }
}

export default withStyles(styles)(withTranslation()(Profile));