import _ from 'underscore';

class AuthManager {
  static instance;
  static createInstance() {
    return new AuthManager();
  }

  static getInstance() {
    if (!AuthManager.instance) {
      AuthManager.instance = AuthManager.createInstance();
    }
    return AuthManager.instance;
  }

  ////////////

  constructor() {
    this.isAuthenticated = false;
    this.bearerToken = undefined;
    this.roles = [];

    this.authChangedSubscribers = [];

    const stateCheck = localStorage.getItem('AuthManager::State');
    if (stateCheck) {
      const stateObject = JSON.parse(stateCheck);

      this.isAuthenticated = stateObject.isAuthenticated;
      this.bearerToken = stateObject.bearerToken;
    }
  }

  updateIsAuthenticated(value) {
    this.isAuthenticated = value;
    this.notifySubscribers(value);
  }

  updateToken(value) {
    this.bearerToken = value;
    this.isAuthenticated = true;
    this.notifySubscribers(true);
  }

  logOut() {
    this.bearerToken = null;
    this.isAuthenticated = false;
    localStorage.removeItem('AuthManager::State');
    this.notifySubscribers(false);
  }

  subscribe(delegate) {
    const subscriptionSearch = _.find(this.authChangedSubscribers, function(item) {
      return item === delegate;
    });

    if (subscriptionSearch === undefined) {
      this.authChangedSubscribers.push(delegate);
    }
  }

  unsubscribe(delegate) {
    this.authChangedSubscribers = _.reject(this.authChangedSubscribers, function(item) {
      return item === delegate;
    });
  }

  notifySubscribers() {
    localStorage.setItem('AuthManager::State', JSON.stringify({ bearerToken: this.bearerToken, isAuthenticated: this.isAuthenticated }));

    this.authChangedSubscribers.forEach(function(item) {
      item();
    });
  }
}

export default AuthManager;
