import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import './i18n';
import './index.css';

import registerServiceWorker from './registerServiceWorker';

import LandingPage from './pages/landing';
import LoginPage from './pages/login';
import DashboardPage from './pages/dashboard';

const Loader = () => <div><img src="/images/NNB_Loader_01.gif" alt="Loading" /></div>;

ReactDOM.render(
    <Suspense fallback={<Loader />}>
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route path="/login" component={LoginPage} />
                <Route path="/dashboard/:tab" component={DashboardPage} />
                <Route path="/dashboard" component={DashboardPage} />
            </Switch>
        </BrowserRouter>
    </Suspense>, document.getElementById('root'));
registerServiceWorker();