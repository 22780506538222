import React, { Component } from 'react';
import AuthManager from '../../managers/authManager';
import AuthService from '../../services/authService';
import ErrorHandler from '../../components/errorHandler';
import ErrorManager from '../../managers/errorManager';

import './index.css';

class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userName: "",
            password: ""
        }

        this.handleLogin = this.handleLogin.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    handleLogin() {
        const auth = AuthManager.getInstance();
        const err = ErrorManager.getInstance();
        const self = this;

        new AuthService().login(this.state.userName, this.state.password).then(function (results) {
            auth.updateToken(results.bearerToken);
            self.props.history.push('/');

        }, function (error) {
            err.broadcastError(error.message);
            auth.logOut();
        });
    }

    handleFormChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render() {
        return (
            <div className="login">
                <img className="leftBG" src="/images/login/leftBG.png" alt="" />
                <img className="chart" src="/images/login/chart.png" alt="" />
                <div className="bigCircleContainer">
                    <div className="bigCircle">
                        <div className="logoContainer">
                            <img className="logo" src="/images/logo.svg" alt="" />
                            <img className="logoInitials" src="/images/logoInitials.svg" alt="" />
                        </div>
                        <div className="form">
                            <p>Username</p>
                            <input type="text" name="userName" onChange={this.handleFormChange} />
                            <p>Password</p>
                            <input type="password" name="password" onChange={this.handleFormChange} />
                            <button name="button" onClick={this.handleLogin}>LOG IN</button>
                            <img className="QR" src="/images/login/QR.jpg" alt="" />
                        </div>
                    </div>
                </div>
                <p className="rightsReserved">@NNB - ALL RIGHTS RESERVED</p>
                <ErrorHandler />
            </div>
        );
    }
}

export default LoginPage;