import React, { Component } from 'react';
import ErrorManager from '../managers/errorManager';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

class ErrorHandler extends Component {
  constructor(props) {
    super(props);

    this.error = ErrorManager.getInstance();

    this.state = {
      errorMessage: '',
      errorOpen: false,
    };
  }

  componentDidMount() {
    this.error.subscribe(this.errorSubscriptionHandler);
  }

  componentWillUnmount() {
    this.error.unsubscribe(this.errorSubscriptionHandler);
  }

  errorSubscriptionHandler = error => {
    this.setState({
      errorOpen: true,
      errorMessage: error,
    });
  };

  closeErrorMessage = () => {
    this.setState({
      errorOpen: false,
      errorMessage: '',
    });
  };

  render() {
    let { errorOpen, errorMessage } = this.state;

    return (
      <div>
        <Dialog open={errorOpen} onClose={this.closeErrorMessage} aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">{'Error Notification'}</DialogTitle>
          <DialogContent>
            <DialogContentText>{errorMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeErrorMessage} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ErrorHandler;
