import Base from './serviceBase';

class AdminService extends Base {
  getConsultants(showAll) {
    return this.jsonRequest(`consultants?showAll=${showAll}`, 'GET');
  }

  getAdmins(showAll) {
    return this.jsonRequest(`admins?showAll=${showAll}`, 'GET');
  }

  getOrdersForConsultant(consultantId, page, pageSize, search) {
    let url = `consultants/${consultantId}/orders`;
    let addedQs = false;
    if (page || pageSize || search) {
      url += '?';
    }
    if (page) {
      url += `${addedQs ? '&' : ''}page=${page}`;
      addedQs = true;
    }
    if (pageSize) {
      url += `${addedQs ? '&' : ''}pageSize=${pageSize}`;
      addedQs = true;
    }
    if (search) {
      url += `${addedQs ? '&' : ''}search=${encodeURI(search)}`;
      addedQs = true;
    }
    return this.jsonRequest(url, 'GET');
  }

  addOrderForConsultant(consultantId, order) {
    return this.jsonRequest(`consultants/${consultantId}/orders`, 'POST', order);
  }

  updateOrderForConsultant(consultantId, orderId, order) {
    return this.jsonRequest(`consultants/${consultantId}/orders/${orderId}`, 'PUT', order);
  }

  deleteOrderForConsultant(consultantId, orderId) {
    return this.jsonRequest(`consultants/${consultantId}/orders/${orderId}`, 'DELETE');
  }

  addConsultant(consultant) {
    return this.jsonRequest('consultants', 'POST', consultant);
  }

  addAdmin(admin) {
    return this.jsonRequest('admins', 'POST', admin);
  }

  updateConsultant(consultant) {
    return this.jsonRequest('consultants', 'PUT', consultant);
  }

  updateAdmin(admin) {
    return this.jsonRequest('admins', 'PUT', admin);
  }

  updatePassword({ consultantUserId, newPassword }) {
    return this.jsonRequest('consultants/password', 'PUT', { consultantUserId, newPassword });
  }
}

export default AdminService;
