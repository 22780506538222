import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Menu,
  MenuItem,
} from '@material-ui/core';
import AuthManager from '../managers/authManager';
import ErrorManager from '../managers/errorManager';
import AuthService from '../services/authService';
import { withTranslation } from 'react-i18next';

const styles = () => ({
  logoArea: {
    cursor: 'pointer',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: 35,
    margin: '10px 10px 10px 20px',
  },
  logoInitials: {
    height: 35,
  },
  userIconContainer: {
    cursor: 'pointer',
  },
  userIcon: {
    height: 35,
    margin: '10px 20px',
  },
  nav: {
    background: '#16203f',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menuContainer: {
    padding: 15,
    width: 450,
  },
});

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginElement: null,
      passwordDialogOpen: false,
      newPassword: '',
      confirmPassword: '',
      currentPassword: '',
    };
  }

  logOut() {
    AuthManager.getInstance().logOut();
  }

  userProfileClicked = event => {
    this.setState({
      loginElement: event.currentTarget,
    });
  };

  userProfileClosed = () => {
    this.setState({
      loginElement: null,
    });
  };

  openPasswordDialog = () => {
    this.setState({
      passwordDialogOpen: true,
    });
  };

  closePasswordDialog = () => {
    this.setState({
      passwordDialogOpen: false,
    });
  };

  handleFormChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  canChangePassword = () => {
    let { currentPassword, newPassword, confirmPassword } = this.state;

    if (currentPassword && newPassword && confirmPassword && newPassword === confirmPassword) {
      return true;
    }
    return false;
  };

  updatePassword = async () => {
    let { currentPassword, newPassword } = this.state;

    if (this.canChangePassword()) {
      try {
        let results = await new AuthService().changePassword(currentPassword, newPassword);
        if (results.success) {
          this.closePasswordDialog();
          this.logOut();
        }
      } catch (error) {
        ErrorManager.getInstance().broadcastError(error.message);
      }
    }
  };

  render() {
    const { classes, t } = this.props;
    const { loginElement, passwordDialogOpen } = this.state;

    let canChangePassword = this.canChangePassword();

    return (
      <nav className={classes.nav}>
        <div
          className={classes.logoArea}
          onClick={() => {
            this.props.history.push('/');
          }}
        >
          <div className={classes.logoContainer}>
            <img className={classes.logo} src="/images/logo.svg" alt="" />
            <img className={classes.logoInitials} src="/images/logoInitials.svg" alt="" />
          </div>
        </div>
        <div className={classes.userIconContainer}>
          <IconButton aria-label="delete" onClick={this.userProfileClicked}>
            <img className={classes.userIcon} src="/images/userIcon.svg" alt="" />
          </IconButton>
          <Menu anchorEl={loginElement} keepMounted open={Boolean(loginElement)} onClose={this.userProfileClosed}>
            <MenuItem
              onClick={() => {
                this.props.history.push('/');
              }}
            >
              {t('nav_bar_home')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.props.history.push('/dashboard');
              }}
            >
              {t('nav_bar_dashboard')}
            </MenuItem>
            <MenuItem onClick={this.openPasswordDialog}>{t('nav_bar_change_password')}</MenuItem>
            <MenuItem
              onClick={() => {
                this.props.history.push('/dashboard/profile');
              }}
            >
              {t('nav_bar_my_profile')}
            </MenuItem>
            <MenuItem onClick={this.logOut}>{t('nav_bar_log_out')}</MenuItem>
          </Menu>
        </div>
        {/* password dialog */}
        <Dialog
          disableBackdropClick={true}
          open={passwordDialogOpen}
          onClose={this.closePasswordDialog}
          aria-labelledby="password-dialog-title"
        >
          <DialogTitle id="password-dialog-title">{t('change_password_dialog_title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('change_password_dialog_message')}</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="currentPassword"
              id="currentPassword"
              label={t('change_password_textbox_current_password')}
              type="password"
              fullWidth
              onChange={this.handleFormChange}
            />
            <TextField
              margin="dense"
              name="newPassword"
              id="newPassword"
              label={t('change_password_textbox_new_password')}
              type="password"
              fullWidth
              onChange={this.handleFormChange}
            />
            <TextField
              margin="dense"
              name="confirmPassword"
              id="confirmPassword"
              label={t('change_password_textbox_confirm_password')}
              type="password"
              fullWidth
              onChange={this.handleFormChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closePasswordDialog}>{t('change_password_action_cancel')}</Button>
            <Button disabled={!canChangePassword} onClick={this.updatePassword} color="primary">
              {t('change_password_action_change')}
            </Button>
          </DialogActions>
        </Dialog>
      </nav>
    );
  }
}

export default withStyles(styles)(withTranslation()(NavBar));
