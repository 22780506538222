import _ from 'underscore';

class ErrorManager {
  static instance;
  static createInstance() {
    return new ErrorManager();
  }

  static getInstance() {
    if (!ErrorManager.instance) {
      ErrorManager.instance = ErrorManager.createInstance();
    }
    return ErrorManager.instance;
  }

  ////////////

  constructor() {
    this.errorChangedSubscribers = [];
  }

  broadcastError(error) {
    this.notifySubscribers(error);
  }

  subscribe(delegate) {
    const subscriptionSearch = _.find(this.errorChangedSubscribers, function(item) {
      return item === delegate;
    });

    if (subscriptionSearch === undefined) {
      this.errorChangedSubscribers.push(delegate);
    }
  }

  unsubscribe(delegate) {
    this.errorChangedSubscribers = _.reject(this.errorChangedSubscribers, function(item) {
      return item === delegate;
    });
  }

  notifySubscribers(error) {
    this.errorChangedSubscribers.forEach(function(item) {
      item(error);
    });
  }
}

export default ErrorManager;
